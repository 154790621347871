import React, { useState } from "react"
import { useSpeechRecognition } from "react-speech-kit"
import Layout from "../components/layout"
import Seo from "../components/seo"
function VoiceToText() {
  const [value, setValue] = useState("")
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: result => {
      setValue(result)
    },
  })

  return (
    <Layout>
      <Seo title="Voice To Text" />

      <section className="lg:h-screen py-6 dark:bg-coolGray-800 dark:text-coolGray-50 flex ">
        <div className="container mx-auto flex flex-col items-center justify-center p-4 space-y-8 md:p-10 md:px-24 xl:px-48 ">
          <aside class="p-4  lg:p-24">
            <div className="flex items-center justify-center p-6 mt-8 lg:mt-0  lg:h-96 xl:h-112 2xl:h-128">
              <img
                src="https://i.ibb.co/FnNhFKX/image-2022-03-21-164426.png "
                alt=""
                className="object-contain lg:h-96 xl:h-112 2xl:h-128  flex justify-center  content-center"
              />
            </div>
            <div class="max-w-xl mx-auto text-center">
              <h1 class="mt-2 text-5xl text-[#33c3b0] ">Voice To Text</h1>

              <div class="mt-8 sm:flex">
                <div class="sm:flex-1">
                  <textarea
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    class="w-full p-3 text-gray border-2 border-gray-400 rounded-lg focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
                  />
                </div>
                <button
                  onMouseDown={listen}
                  onMouseUp={stop}
                  class="flex items-center justify-between w-full px-5 py-3 mt-4 font-medium text-white bg-[#33c3b0] rounded-lg sm:w-auto sm:mt-0 sm:ml-4 hover:bg-teal-400"
                >
                  Listen 🎤
                </button>
                {listening && <div>Go ahead I'm listening</div>}
              </div>
            </div>
          </aside>

          <nav
            aria-label="breadcrumb"
            className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
          >
            <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
              <li className="flex items-center">
                <a
                  rel="noopener noreferrer"
                  href="/"
                  title="Back to homepage"
                  className="flex items-center hover:underline"
                >
                  Home
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/know"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  About
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/started"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Features
                </a>
              </li>
              <li className="flex items-center space-x-1">
                <span className="dark:text-coolGray-400">/</span>
                <a
                  rel="noopener noreferrer"
                  href="/contact"
                  className="flex items-center px-1 capitalize hover:underline"
                >
                  Contact
                </a>
              </li>
            </ol>

            <footer className="text-center text-sm">
              © {new Date().getFullYear()}, An Idea by
              {` `}
              <a href="https://www.chakama.co.zw">George Chakama</a>
            </footer>
          </nav>
        </div>
      </section>
    </Layout>
  )
}
export default VoiceToText
